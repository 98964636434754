import { Box, Container, styled } from '@mui/material';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import { useTransition } from '../hooks/TransitionProvider';
import transitions from '../modules/transitions';

const StyledContainer = styled(Container)({
  paddingBottom: '3rem',
});

const MotionBox = motion(Box);

const Content = ({ children = null }) => {
  const transition = useTransition();
  return (
    <StyledContainer>
      {transition ? (
        <MotionBox
          initial="initial"
          animate="animate"
          exit="exit"
          variants={transitions[transition]}
        >
          {children}
        </MotionBox>
      ) : children}
    </StyledContainer>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Content;
