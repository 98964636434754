import PropTypes from 'prop-types';
import React from "react";

import { useCache } from "./CacheProvider";

const OriginContext = React.createContext({});

const useOrigins = () => {
  const { loading, origins } = React.useContext(OriginContext);

  return { loading, origins };
};

// eslint-disable-next-line react/display-name
const withOrigins = (Component) => (props) => {
  const origins = useOrigins();

  return <Component {...props} origins={origins} />;
};

const OriginProvider = ({ children = null }) => {
  const cache = useCache();
  const [origins, setOrigins] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const controller = new AbortController();
    cache.fetchData(setOrigins, setLoading, 'origins', null, controller);
    return () => controller.abort();
  }, []);

  return (
    <OriginContext.Provider value={{ loading, origins }}>
      {children}
    </OriginContext.Provider>
  );
};

OriginProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { OriginProvider, useOrigins, withOrigins };

