import PropTypes from 'prop-types';
import React from 'react';

import { OriginProvider } from './OriginProvider';

const SETTINGS = {
  filter: {
    bookmarked: false,
    origin: 'all',
  },
  limit: 10,
  page: 1,
  search: '',
  sortBy: 1,
}

const SORT = [
  { key: 1, name: 'catalog.sortByLotNoAsc', property: 'number', type: 'numeric', order: 1 },
  { key: 2, name: 'catalog.sortByLotNoDesc', property: 'number', type: 'numeric', order: -1 },
  { key: 3, name: 'catalog.sortByEstimateAsc', property: 'estimate', type: 'numeric', order: 1 },
  { key: 4, name: 'catalog.sortByEstimateDesc', property: 'estimate', type: 'numeric', order: -1 },
  { key: 5, name: 'catalog.sortByResultAsc', property: 'result', type: 'numeric', order: 1 },
  { key: 6, name: 'catalog.sortByResultDesc', property: 'result', type: 'numeric', order: -1 },
  { key: 7, name: 'catalog.sortByOriginAsc', property: 'origindetail', type: 'string', order: 1 },
  { key: 8, name: 'catalog.sortByOriginDesc', property: 'origindetail', type: 'string', order: -1 },
  { key: 9, name: 'catalog.sortByWidthAsc', property: 'width', type: 'numeric', order: 1 },
  { key: 10, name: 'catalog.sortByWidthDesc', property: 'width', type: 'numeric', order: -1 },
  { key: 11, name: 'catalog.sortByHeightAsc', property: 'height', type: 'numeric', order: 1 },
  { key: 12, name: 'catalog.sortByHeightDesc', property: 'height', type: 'numeric', order: -1 },
];

const LIMIT = [
  { key: 1, value: 10 },
  { key: 2, value: 20 },
  { key: 3, value: 30 },
  { key: 4, value: 50 },
  { key: 5, value: 80 },
  { key: 6, value: 130 },
  { key: 7, value: 210 },
  { key: 8, value: 340 },
];

const CatalogContext = React.createContext({});

const useCatalogSettings = () => {
  const { setSettings, settings } = React.useContext(CatalogContext);

  if (!setSettings || !settings) return {};

  const handleChange = (value) => {
    if (typeof value !== 'object') return;
    setSettings({
      ...settings,
      ...value
    });
  };

  return { ...settings, onChange: handleChange };
};

// eslint-disable-next-line react/display-name
const withCatalogSettings = (Component) => (props) => {
  const settings = useCatalogSettings();

  return <Component {...props} settings={settings} />;
};

const CatalogProvider = ({
  children = null,
  defaultSettings = null,
}) => {
  const [settings, setSettings] = React.useState(defaultSettings || SETTINGS);

  return (
    <CatalogContext.Provider value={{ setSettings, settings }}>
      <OriginProvider>
        {children}
      </OriginProvider>
    </CatalogContext.Provider>
  );
};

CatalogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  defaultSettings: PropTypes.shape({}),
};

export { CatalogProvider, LIMIT, SETTINGS, SORT, useCatalogSettings, withCatalogSettings };
