import { Box, Typography, styled, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import HeaderActions from './HeaderActions';
import Navigation from './Navigation';
import Logo from '../assets/Logo';
import { useConfig } from '../hooks/AppProvider';

const StyledBox = styled(Box)(({ theme }) => {
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const mediumMQ = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const styles = mobile ? {
    flexDirection: 'column-reverse',
    '.logo': {
      padding: '1.5rem 1.5rem 1rem 1.5rem',
      alignSelf: 'center',
    },
  } : {
    alignItems: 'center',
    justifyContent: mediumMQ ? 'left' : 'center',
    padding: '3rem 1.5rem 1.5rem 1.5rem',
    position: 'relative',
  };
  return ({
    display: 'flex',
    backgroundImage: theme.palette.background.bluegradient,
    a: { textDecoration: 'none' },
    ...styles,
  });
});

const Header = () => {
  const { refs } = useConfig();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const mediumMQ = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  return (
    <>
      <StyledBox ref={refs.head}>
        <Link to="/" className='logo'>
          <Logo />
          <Typography
            p="0.25rem 0 0 0"
            sx={{
              color: 'primary.contrastText',
              fontSize: mobile ? '2vw' : '0.75rem',
              textAlign: mediumMQ ? 'left' : 'center',
              textShadow: '1px 1px 1px rgba(0 0 0 / 0.4)'
            }}
          >
            established 1884 in England - International Auctioneers of rare Carpets & Textiles
          </Typography>
        </Link>
        <HeaderActions />
      </StyledBox>
      <Navigation />
    </>
  );
};

export default Header;
