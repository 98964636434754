import { createTheme } from '@mui/material/styles';

import DroidSansWoff2 from './fonts/droidsans-webfont.woff2';
import DroidSansBoldWoff2 from './fonts/droidsans-bold-webfont.woff2';
import BernhardModernWoff2 from './fonts/bernhard-modern-bq-regular-webfont.woff2';

const theme = createTheme({
  typography: {
    fontFamily: 'droid_sans',
  },
  palette: {
    primary: {
      main: '#1e4164',
    },
    light: {
      main: '#ffffff',
    },
    text: {
      primary: '#736357',
      secondary: '#362f2d',
      mobileSubnav: 'rgba(255, 255, 255, 0.5)',
      activeMobileSubnav: '#ffffff',
    },
    background: {
      lightgray: '#F7F7F7',
      gray: '#EAEBEB',
      lightestbrown: '#f6ead8',
      lighterbrown: '#E3DBD3',
      lightbrown: '#C7B29A',
      brown: '#a67c52',
      lightblue: '#47739d',
      lighterblue: '#4D80B1',
      blue: '#335473',
      darkblue: '#214060',
      bluegradient: 'linear-gradient(180deg, #335473 0%, #214060 100%)',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          overflow-x: hidden;
          background-color: #F7F7F7;
        }
        ::-webkit-scrollbar {
          width: 10px;
        }
        ::-webkit-scrollbar-track {
          border: none;
          // background-color: #1e4164;
        }
        ::-webkit-scrollbar-thumb {
          background: linear-gradient(45deg, #ccc, #ddd);
        }
        @font-face {
          font-family: 'droid_sans';
          font-display: swap;
          font-style: normal;
          font-weight: normal;
          src: local('Droid Sans'), url(${DroidSansWoff2}) format('woff2');
        }
        @font-face {
          font-family: 'droid_sans';
          font-display: swap;
          font-style: normal;
          font-weight: bold;
          src: local('Droid Sans'), url(${DroidSansBoldWoff2}) format('woff2');
        }
        @font-face {
          font-family: 'bernhard_modern_bq';
          font-display: swap;
          font-style: normal;
          font-weight: normal;
          src: local('BernhardModernBQ'), url(${BernhardModernWoff2}) format('woff2');
        }
        h1 {
          font-family: 'bernhard_modern_bq';
        }
        h2 {
          font-weight: normal;
        }
        img {
          max-width: 100%;
        }
        .logo {
          font-family: 'bernhard_modern_bq';
          font-size: 0.875rem;
          margin-bottom: 0;
        }
        .no-margin {
          margin: 0;
        }
        .info-box {
          padding: 1rem;
          color: rgb(1, 67, 97);
          background-color: rgb(229, 246, 253);
          border: 1px solid rgb(220, 237, 244);
          border-radius: 4px;
        }
        .columns {
          column-gap: 1.5rem;
        }
        .columns-2 {
          column-count: 2;
        }
        .columns-3 {
          column-count: 3;
        }
        @media screen and (min-width: 900px) {
          h1 {
            font-weight: normal;
            font-size: 3rem;
            margin: 3rem 0 1rem 0;
          }
        }
        @media screen and (max-width: 760px) {
          .ck-content table colgroup {
            display: none;
          }
          .ck-content table,
          .ck-content .table tr,
          .ck-content .table th,
          .ck-content .table td {
            display: block;
          }
        }

        /* ckeditor5-block-quote/theme/blockquote.css */
        .ck-content blockquote {
            overflow: hidden;
            padding-right: 1.5em;
            padding-left: 1.5em;
            margin-left: 0;
            margin-right: 0;
            font-style: italic;
            border-left: solid 5px hsl(0, 0%, 80%);
        }
        /* ckeditor5-block-quote/theme/blockquote.css */
        .ck-content[dir="rtl"] blockquote {
            border-left: 0;
            border-right: solid 5px hsl(0, 0%, 80%);
        }
        /* ckeditor5-font/theme/fontsize.css */
        .ck-content .text-tiny {
            font-size: .7em;
        }
        /* ckeditor5-font/theme/fontsize.css */
        .ck-content .text-small {
            font-size: .85em;
        }
        /* ckeditor5-font/theme/fontsize.css */
        .ck-content .text-big {
            font-size: 1.4em;
        }
        /* ckeditor5-font/theme/fontsize.css */
        .ck-content .text-huge {
            font-size: 1.8em;
        }
        /* ckeditor5-highlight/theme/highlight.css */
        .ck-content .marker-yellow {
            background-color: var(--ck-highlight-marker-yellow);
        }
        /* ckeditor5-highlight/theme/highlight.css */
        .ck-content .marker-green {
            background-color: var(--ck-highlight-marker-green);
        }
        /* ckeditor5-highlight/theme/highlight.css */
        .ck-content .marker-pink {
            background-color: var(--ck-highlight-marker-pink);
        }
        /* ckeditor5-highlight/theme/highlight.css */
        .ck-content .marker-blue {
            background-color: var(--ck-highlight-marker-blue);
        }
        /* ckeditor5-highlight/theme/highlight.css */
        .ck-content .pen-red {
            color: var(--ck-highlight-pen-red);
            background-color: transparent;
        }
        /* ckeditor5-highlight/theme/highlight.css */
        .ck-content .pen-green {
            color: var(--ck-highlight-pen-green);
            background-color: transparent;
        }
        /* ckeditor5-image/theme/image.css */
        .ck-content .image {
            display: table;
            clear: both;
            text-align: center;
            margin: 0.9em auto;
            min-width: 50px;
        }
        /* ckeditor5-image/theme/image.css */
        .ck-content .image img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            min-width: 100%;
        }
        /* ckeditor5-image/theme/image.css */
        .ck-content .image-inline {
            /*
            * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
            * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
            * This strange behavior does not happen with inline-flex.
            */
            display: inline-flex;
            max-width: 100%;
            align-items: flex-start;
        }
        /* ckeditor5-image/theme/image.css */
        .ck-content .image-inline picture {
            display: flex;
        }
        /* ckeditor5-image/theme/image.css */
        .ck-content .image-inline picture,
        .ck-content .image-inline img {
            flex-grow: 1;
            flex-shrink: 1;
            max-width: 100%;
        }
        /* ckeditor5-image/theme/imagecaption.css */
        .ck-content .image > figcaption {
            display: table-caption;
            caption-side: bottom;
            word-break: break-word;
            color: var(--ck-color-image-caption-text);
            background-color: transparent;
            padding: .6em;
            font-size: .75em;
            outline-offset: -1px;
            text-align: left;
            font-style: italic;
        }
        /* ckeditor5-image/theme/imageresize.css */
        .ck-content .image.image_resized {
            max-width: 100%;
            display: block;
            box-sizing: border-box;
        }
        /* ckeditor5-image/theme/imageresize.css */
        .ck-content .image.image_resized img {
            width: 100%;
        }
        /* ckeditor5-image/theme/imageresize.css */
        .ck-content .image.image_resized > figcaption {
            display: block;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-block-align-left,
        .ck-content .image-style-block-align-right {
            max-width: calc(100% - 2em);
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-align-left,
        .ck-content .image-style-align-right {
            clear: none;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-side {
            float: right;
            margin-left: 2em;
            max-width: 50%;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-align-left {
            float: left;
            margin-right: 2em;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-align-center {
            margin-left: auto;
            margin-right: auto;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-align-right {
            float: right;
            margin-left: 2em;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-block-align-right {
            margin-right: 0;
            margin-left: auto;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-style-block-align-left {
            margin-left: 0;
            margin-right: auto;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content p + .image-style-align-left,
        .ck-content p + .image-style-align-right,
        .ck-content p + .image-style-side {
            margin-top: 0;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-inline.image-style-align-left,
        .ck-content .image-inline.image-style-align-right {
            margin-top: 1em;
            margin-bottom: 1em;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-inline.image-style-align-left {
            margin-right: 1em;
        }
        /* ckeditor5-image/theme/imagestyle.css */
        .ck-content .image-inline.image-style-align-right {
            margin-left: 1em;
        }
        /* ckeditor5-language/theme/language.css */
        .ck-content span[lang] {
            font-style: italic;
        }
        /* ckeditor5-media-embed/theme/mediaembed.css */
        .ck-content .media {
            clear: both;
            margin: 0.9em 0;
            display: block;
            min-width: 15em;
        }
        /* ckeditor5-table/theme/table.css */
        .ck-content .table {
            margin: 0.9em auto;
            display: table;
        }
        /* ckeditor5-table/theme/table.css */
        .ck-content .table table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            height: 100%;
        }
        /* ckeditor5-table/theme/table.css */
        .ck-content .table table td,
        .ck-content .table table th {
            min-width: 2em;
            padding: .4em;
        }
        /* ckeditor5-table/theme/table.css */
        .ck-content .table table th {
            font-weight: bold;
            background: hsla(0, 0%, 0%, 5%);
        }
        /* ckeditor5-table/theme/table.css */
        .ck-content[dir="rtl"] .table th {
            text-align: right;
        }
        /* ckeditor5-table/theme/table.css */
        .ck-content[dir="ltr"] .table th {
            text-align: left;
        }
        /* ckeditor5-table/theme/tablecaption.css */
        .ck-content .table > figcaption {
            display: table-caption;
            caption-side: top;
            word-break: break-word;
            text-align: center;
            color: var(--ck-color-table-caption-text);
            background-color: var(--ck-color-table-caption-background);
            padding: .6em;
            font-size: .75em;
            outline-offset: -1px;
        }
        /* ckeditor5-table/theme/tablecolumnresize.css */
        .ck-content .table .ck-table-resized {
            table-layout: fixed;
        }
        /* ckeditor5-table/theme/tablecolumnresize.css */
        .ck-content .table table {
            overflow: hidden;
        }
        /* ckeditor5-table/theme/tablecolumnresize.css */
        .ck-content .table td,
        .ck-content .table th {
            position: relative;
        }
        /* ckeditor5-horizontal-line/theme/horizontalline.css */
        .ck-content hr {
            margin: 15px 0;
            height: 4px;
            background: hsl(0, 0%, 87%);
            border: 0;
        }
        /* ckeditor5-list/theme/liststyles.css */
        .ck-content ol {
            list-style-type: none;
            counter-reset: item;
            margin: 1em 0;
            padding: 0;
        }
        .ck-content ol li {
          margin-bottom: 0.5rem;
          padding-left: 2em;
          position: relative;
          top: 0em;
          left: 0em;
        }
        .ck-content ol li:before {
          content: counters(item,".") ". ";
          counter-increment: item;
          width: 1em;
          position: absolute;
          top: 0;
          left: 0;
        }
        .ck-content > ol > li::first-line,
        .ck-content > ol > li:before {
          font-size: 120%;
        }
        /* ckeditor5-list/theme/liststyles.css */
        .ck-content ul {
            list-style-type: square;
            padding-left: 2em;
        }
        /* ckeditor5-list/theme/liststyles.css */
        .ck-content ul ul {
            list-style-type: disc;
        }
        /* ckeditor5-list/theme/liststyles.css */
        .ck-content ul ul ul {
            list-style-type: circle;
        }
        /* ckeditor5-list/theme/liststyles.css */
        .ck-content ul ul ul ul {
            list-style-type: square;
        }
      `
    }
  }
});

theme.typography.h1 = {
  fontFamily: 'bernhard_modern_bq',
  fontWeight: 'bold',
  margin: '1.5rem 0 1rem 0',
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
    fontWeight: 'normal',
    margin: '3rem 0 1rem 0',
  }
};

theme.typography.h2 = {
  fontFamily: 'droid_sans',
  fontWeight: 'normal',
  fontSize: '1.25rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

export default theme;
