import React from 'react';
import { styled } from '@mui/material/styles';

const StyledSVG = styled('svg')(({ theme }) => ({
  width: 650,
  maxWidth: '100%',
  fill: 'white',
  filter: 'drop-shadow(3px 3px 2px rgba(0 0 0 / 0.4))',
  [theme.breakpoints.between(900, 980)]: {
    width: 580,
  }
}));

const Logo = () => (
  <StyledSVG
    id="rippon-boswell-logo"
    data-name="Rippon Boswell & Co. Logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 892 59.55"
  >
    <path d="M18.36,61c-1.94-.14-3.89-.22-5.83-.22-2.3,0-4.61.08-6.91.22-1.08.07-2.09.14-3.24.29H1.8a.84.84,0,0,1-.94-.72.89.89,0,0,1,.65-.79c.65-.29,4-.72,6.84-1.16.15-2.3.22-4.61.29-7V27.1c0-3,0-6-.07-9-.07-1.65-.22-3.31-.29-5-1.66-.21-3.24-.43-4.82-.72a9.1,9.1,0,0,1-2.09-.43.81.81,0,0,1-.58-.72.86.86,0,0,1,.94-.65c2,0,4,.22,6.05.29,1.87.07,3.74.07,5.69.07,2.3,0,4.6,0,7-.14,2.09-.07,4.18-.22,6.34-.22a38.27,38.27,0,0,1,5.54.36c1.37.22,7.13.8,10.51,4.9a14.73,14.73,0,0,1,1.3,1.8,11.56,11.56,0,0,1,1.15,5.11,16.45,16.45,0,0,1-.36,3.1c-.93,4-3.17,6.41-6.55,8.5a21.18,21.18,0,0,1-5.76,2.44,43.65,43.65,0,0,1-4.32.8c.72,1,1.51,2.08,2.23,3.16.43.58.86,1.16,1.22,1.73.8,1.08,1.52,2.09,2.24,3.17s1.51,2.16,2.3,3.24,1.3,1.8,2,2.74c1.51,2.16,3.45,5.54,5.9,6.76a3.43,3.43,0,0,0,1.8.44,8.66,8.66,0,0,0,2-.36A5.48,5.48,0,0,1,49,58.07a.82.82,0,0,1,.79.86c0,.43-.43.72-1.08,1.3a10.76,10.76,0,0,1-7.42,2.66c-3.24,0-5.54-1.15-8.93-6.12-.72-1.08-1.58-2.16-2.37-3.24-.58-.79-1.16-1.66-1.73-2.52-3.1-4.39-6.27-8.71-9.36-13.1-.43-.58-.79-.94-.72-1.3a.93.93,0,0,1,.93-.86,3.2,3.2,0,0,1,.8.14c.21,0,.43.07.64.07.72.07,1.52,0,2.24,0s1.36.07,2,.07c.29,0,4.9-.07,7.49-1.36a8.29,8.29,0,0,0,2.88-2.31,8.65,8.65,0,0,0,2.16-6.19,13.6,13.6,0,0,0-.72-4.32A14.69,14.69,0,0,0,31.9,15.3a18.05,18.05,0,0,0-10.51-3,13.37,13.37,0,0,0-4.76.79c-.14,3.24-.28,6.56-.36,9.8,0,2.08.08,4.17.08,6.26,0,5.83-.08,11.67-.08,17.43,0,4,.08,8.13.15,12.17,1.94.21,3.89.5,5.76.86.57.07,1.87.22,1.87,1.15-.07.65-1.08.65-1.22.65-.51,0-.94-.14-1.44-.14Z" transform="translate(-0.79 -6.15)" />
    <path d="M61.27,46c.07-2.73.07-5.47.07-8.28V21c-.07-2.59-.21-5.25-.36-7.84-1.65-.22-3.31-.51-5-.8-1.22-.21-1.8-.21-2.3-.79a1,1,0,0,1-.14-.43c0-.43.57-.72,1.08-.72s1,.14,1.44.14a22.2,22.2,0,0,0,2.3.15c2.59.14,5.19.29,7.78.29,1.73,0,3.53-.08,5.33-.22,1.22-.07,2.44-.22,3.6-.22a4.17,4.17,0,0,1,.93-.07c.07,0,1,.07,1,.72s-.86.87-2,1.08c-1.72.36-3.6.65-5.4.87-.14,3.24-.28,6.48-.28,9.72s-.08,6.26-.08,9.43c0,4.1.08,8.28,0,12.38,0,2.74,0,5.55.08,8.28.07,1.88.14,3.82.21,5.69a39.5,39.5,0,0,1,4,.58,15.92,15.92,0,0,1,1.8.36c.65.14,1.66.29,1.66,1s-.94.72-1,.72a12.72,12.72,0,0,1-1.8-.15l-4-.21c-1.22-.07-2.52-.15-3.82-.15-2,0-4,.08-6,.15a41.93,41.93,0,0,0-4.32.36c-.44,0-.94.07-1.44.07s-1.16-.07-1.08-.65.57-.79.64-.86c.8-.29,5.91-1,6.77-1.15.15-3.17.29-6.41.29-9.58Z" transform="translate(-0.79 -6.15)" />
    <path d="M97.56,58.64a51.18,51.18,0,0,1,7.42,1c1.08.22,1.87.36,1.87,1.08a.71.71,0,0,1-.72.65c-.51,0-1.23-.07-1.8-.07-1.15-.15-2-.22-2.81-.29-2-.14-4.39-.22-6.55-.22a104.27,104.27,0,0,0-10.59.51c-.43,0-.93.07-1.37.07,0,0-1.15.07-1.15-.72s1.23-1,2-1.08c2.16-.36,3.81-.58,5.47-.86.14-3,.29-5.91.29-8.86.07-4.39,0-8.71,0-13,0-5.77.14-11.53,0-17.29-.07-2-.15-4.1-.22-6.12-1.51-.21-3-.43-4.46-.72a11.69,11.69,0,0,1-2.38-.57,1,1,0,0,1-.57-.8c0-.43.64-.64,1.15-.64a10.27,10.27,0,0,1,1.37.14l3.16.22c2.24.14,4.47.21,6.77.21,3.24,0,6.48-.14,9.72-.36,1.8-.07,3.68-.14,5.48-.14.86,0,6.76-.22,11.23,2.45a13.64,13.64,0,0,1,2.38,1.94,6.71,6.71,0,0,1,1.36,1.66,11.12,11.12,0,0,1,2,6.55,11.44,11.44,0,0,1-2.23,7.13c-2,3.09-5.41,5.83-11.09,7.27a40.17,40.17,0,0,1-6.56.87c-1,0-2.08-.08-3.09-.15-2.09-.07-4.11-.14-6.12-.29Zm-.15-34.85c0,4.25,0,8.5.08,12.82,2.3.14,4.61.29,6.91.29,5,0,7.63-1,9.94-2.74a8.52,8.52,0,0,0,1.8-1.73,13.59,13.59,0,0,0,2.16-7.27,13.22,13.22,0,0,0-4.18-9.72,9.84,9.84,0,0,0-2-1.44,14.29,14.29,0,0,0-7.7-1.8,17.2,17.2,0,0,0-6.63,1.15C97.56,16.81,97.41,20.26,97.41,23.79Z" transform="translate(-0.79 -6.15)" />
    <path d="M145.58,58.64a51.18,51.18,0,0,1,7.42,1c1.08.22,1.87.36,1.87,1.08a.71.71,0,0,1-.72.65c-.5,0-1.22-.07-1.8-.07-1.15-.15-1.94-.22-2.81-.29-1.94-.14-4.39-.22-6.55-.22a103.93,103.93,0,0,0-10.58.51c-.44,0-.94.07-1.37.07,0,0-1.16.07-1.16-.72s1.23-1,2-1.08c2.16-.36,3.82-.58,5.47-.86.15-3,.29-5.91.29-8.86.07-4.39,0-8.71,0-13,0-5.77.15-11.53,0-17.29-.07-2-.14-4.1-.21-6.12-1.52-.21-3-.43-4.47-.72a11.85,11.85,0,0,1-2.38-.57,1,1,0,0,1-.57-.8c0-.43.65-.64,1.15-.64a10.27,10.27,0,0,1,1.37.14l3.17.22c2.23.14,4.46.21,6.77.21,3.24,0,6.48-.14,9.72-.36,1.8-.07,3.67-.14,5.47-.14.86,0,6.77-.22,11.23,2.45a13.25,13.25,0,0,1,2.38,1.94,7,7,0,0,1,1.37,1.66,11.2,11.2,0,0,1,1.94,6.55,11.44,11.44,0,0,1-2.23,7.13c-2,3.09-5.4,5.83-11.09,7.27a40,40,0,0,1-6.55.87c-1,0-2.09-.08-3.1-.15-2.09-.07-4.1-.14-6.12-.29Zm-.14-34.85c0,4.25,0,8.5.07,12.82,2.3.14,4.61.29,6.91.29,5,0,7.64-1,9.94-2.74a8.27,8.27,0,0,0,1.8-1.73,13.59,13.59,0,0,0,2.16-7.27,13.22,13.22,0,0,0-4.18-9.72,9.78,9.78,0,0,0-2-1.44,14.31,14.31,0,0,0-7.71-1.8,17.16,17.16,0,0,0-6.62,1.15C145.58,16.81,145.44,20.26,145.44,23.79Z" transform="translate(-0.79 -6.15)" />
    <path d="M181.51,34.45a20,20,0,0,1,.29-2.16,25.81,25.81,0,0,1,4.68-11.59,21.35,21.35,0,0,1,2-2.45,21.31,21.31,0,0,1,4.32-3.67,31.27,31.27,0,0,1,9.87-4.18,32.13,32.13,0,0,1,5.83-.65,36.5,36.5,0,0,1,10.59,1.44,25.5,25.5,0,0,1,3.81,1.73,21.87,21.87,0,0,1,4.75,3.24,27.12,27.12,0,0,1,3.75,4.1c4.25,6.12,4.32,13.61,4.32,15.13a28.86,28.86,0,0,1-3.39,13.68,26.69,26.69,0,0,1-6.26,7.41,27.69,27.69,0,0,1-2.66,1.8,26.88,26.88,0,0,1-14.84,4c-2.3,0-11.88,0-19.51-7.13a22,22,0,0,1-5.62-8.07,27.35,27.35,0,0,1-1.94-8.64c-.07-.86,0-1.73,0-2.66ZM200,13.78a15.69,15.69,0,0,0-2.37,2,14.26,14.26,0,0,0-1,1.08,30.1,30.1,0,0,0-2.74,4.18,34,34,0,0,0-2.95,13.46c0,1.15-.07,2.31,0,3.46s.14,2,.22,3a28.28,28.28,0,0,0,2.59,9.29,19.91,19.91,0,0,0,2.45,4.18,17,17,0,0,0,1.51,1.8,13.16,13.16,0,0,0,1.08,1c.43.29.86.65,1.22.94a14,14,0,0,0,4.4,2,18.47,18.47,0,0,0,4.75.72,13.82,13.82,0,0,0,6.55-1.59,12.61,12.61,0,0,0,2.88-1.94A20.91,20.91,0,0,0,221,54.83a20.19,20.19,0,0,0,2.95-5.19,33.5,33.5,0,0,0,2.3-13c0-7.06-.93-10.66-2.16-14-.79-2-2.88-7.27-8.56-9.86A14.19,14.19,0,0,0,211,11.48a20.85,20.85,0,0,0-3.1-.22A18.77,18.77,0,0,0,200,13.78Z" transform="translate(-0.79 -6.15)" />
    <path d="M285.12,47.91c-.07-4.53-.07-9.14-.14-13.75-.08-1.58-.08-3.17-.22-4.75-.07-1.95-.22-3.82-.36-5.69-.15-2.3-.29-4.54-.58-6.84a27.9,27.9,0,0,0-.65-3.6c-1.65-.22-3.88-.5-6.12-.94-1.29-.28-1.87-.5-1.87-1.15a.77.77,0,0,1,.79-.65c.51,0,1,.08,1.52.08l2.8.21c1.95.15,3.89.22,5.84.22a47.92,47.92,0,0,0,5.61-.29c.72-.07,1.44-.07,2.16-.14a2.72,2.72,0,0,1,.8-.08.93.93,0,0,1,.93.79.65.65,0,0,1-.29.58,10.06,10.06,0,0,1-3,.72,29.54,29.54,0,0,0-3,.51c-.44,2.37-.8,4.75-1,7.12-.58,5.84-.72,11.67-.87,17.43-.14,3.67-.21,7.27-.21,10.94,0,2.52.07,5.12.21,7.71.08,1.3.08,2.66.22,4,0,.29.14.65.14,1s-.43.86-.72.86-.86-.72-1.58-1.58c-.43-.5-.86-.94-1.3-1.44-.79-.94-1.58-1.95-2.45-2.88s-1.51-1.73-2.23-2.67l-2.37-2.8c-1.52-1.8-3-3.6-4.54-5.33-1.08-1.3-2.23-2.67-3.38-4s-2-2.45-3.1-3.67c-2.09-2.45-4.18-5-6.27-7.42-1-1.23-2.08-2.45-3.16-3.67a23.22,23.22,0,0,0-2.16-2.52c-.87-1.08-1.88-2.09-2.81-3.1,0,6.48,0,13,.29,19.59.07,2.73.28,5.54.5,8.35.14,1.8.36,3.53.58,5.26.14,1.36.36,2.8.57,4.17,2.16.22,4.25.58,6.34.94.86.14,2.23.29,2.16,1.22,0,.22-.29.58-.86.58-1.37,0-2.74-.15-4-.22-1.73-.14-3.45-.21-5.25-.21-3.1,0-6.12.14-9.29.43-.22,0-.51.07-.87.07s-.93-.22-.93-.72c.07-.79,1.15-.79,1.65-.94s1.08-.28,1.66-.36c1.08-.28,2.23-.43,3.38-.64.36-2.16.65-4.25.87-6.41s.29-4.54.43-6.77c.14-2,.29-4,.36-6,.07-2.37.07-4.68.14-7.06.08-2.16.15-4.39.15-6.55s.07-4.53.07-6.84c-.29-.43-.65-.86-.94-1.29a42,42,0,0,0-4.32-4,10.6,10.6,0,0,0-2.66-1.16c-.65-.21-1.66-.36-1.66-1.29.08-.72,1.16-.79,1.3-.79.58,0,1.22.14,1.73.14a14.89,14.89,0,0,0,1.73.07c1.72,0,3.6,0,5.54-.07l2.88-.22Z" transform="translate(-0.79 -6.15)" />
    <path d="M329.83,60.88c-2.38,0-4.68,0-7,.07-1.58.07-3.09.21-4.68.29a5.6,5.6,0,0,1-1.08.07c-.72,0-1.08-.43-1.08-.79a.82.82,0,0,1,.51-.65,4.59,4.59,0,0,1,1.65-.43c1.8-.29,3.6-.51,5.33-.8.15-2.3.22-4.53.29-6.77,0-3.38-.07-6.76-.07-10.15.07-6.55,0-13.18,0-19.73,0-2.95-.07-6-.15-9-1.44-.14-2.88-.43-4.25-.65-.43-.07-.79-.07-1.22-.14s-1.94-.36-1.94-1.15a1.06,1.06,0,0,1,1.08-.8,5.23,5.23,0,0,1,.93.15,6,6,0,0,0,1.15.07c2.38.22,4.69.36,7.13.36,4.4,0,8.72-.58,13.11-.58.07,0,10.66-.14,15.62,3,3.24,2.09,5,4.82,5,8.78a12.19,12.19,0,0,1-2.95,7.78,13.81,13.81,0,0,1-2.59,2.38,32.14,32.14,0,0,1-3.17,2A32.7,32.7,0,0,1,345.89,36a30.52,30.52,0,0,1,8.71,1.52,10.85,10.85,0,0,1,5.47,3.81,10.33,10.33,0,0,1,1.51,5.26,13.38,13.38,0,0,1-7.05,11.52c-4,2.3-10,3.24-14.48,3.24-1.29,0-2.52-.07-3.81-.14s-2.6-.29-3.89-.36Zm1.8-2.09a29.28,29.28,0,0,0,6.48.86,21,21,0,0,0,8.42-1.44A10,10,0,0,0,348.77,57a9.14,9.14,0,0,0,2.3-2.38,10.84,10.84,0,0,0,1.8-6,15.33,15.33,0,0,0-.36-3,9.78,9.78,0,0,0-6.84-7.7c-2.88-.87-7.42-.72-14.11-.65Zm0-23.12c2,0,4.1,0,6.19-.07,3.31-.07,5,0,8.14-1.29a12.36,12.36,0,0,0,1.8-.87,12.36,12.36,0,0,0,1.65-1.37,11.3,11.3,0,0,0,.87-.93c.07-.15.43-.72.72-1.16a10.63,10.63,0,0,0,1.15-5.11,13.72,13.72,0,0,0-1.58-6.33,9.75,9.75,0,0,0-1.23-1.8c-.29-.36-.93-1.08-1.22-1.37-.51-.36-1.08-.79-1.59-1.08-1.87-1.23-4.24-2.31-9-2.31a33.46,33.46,0,0,0-5.9.65Z" transform="translate(-0.79 -6.15)" />
    <path d="M369.07,34.45c.07-.72.14-1.44.28-2.16A26.07,26.07,0,0,1,374,20.7a22.51,22.51,0,0,1,2-2.45,20.74,20.74,0,0,1,4.32-3.67,31.06,31.06,0,0,1,9.87-4.18,32,32,0,0,1,5.83-.65,36.4,36.4,0,0,1,10.58,1.44,25.16,25.16,0,0,1,3.82,1.73,21.87,21.87,0,0,1,4.75,3.24,27.12,27.12,0,0,1,3.75,4.1c4.24,6.12,4.32,13.61,4.32,15.13a28.86,28.86,0,0,1-3.39,13.68,26.69,26.69,0,0,1-6.26,7.41,27.8,27.8,0,0,1-2.67,1.8,26.85,26.85,0,0,1-14.83,4c-2.3,0-11.88,0-19.51-7.13A21.91,21.91,0,0,1,371,47.12a27.35,27.35,0,0,1-1.94-8.64c-.08-.86,0-1.73,0-2.66Zm18.5-20.67a15.69,15.69,0,0,0-2.37,2,12.47,12.47,0,0,0-1,1.08,28.36,28.36,0,0,0-2.74,4.18,33.79,33.79,0,0,0-2.95,13.46c0,1.15-.07,2.31,0,3.46s.14,2,.21,3a28.23,28.23,0,0,0,2.6,9.29,20.46,20.46,0,0,0,2.44,4.18,18.41,18.41,0,0,0,1.52,1.8,13.16,13.16,0,0,0,1.08,1c.43.29.86.65,1.22.94a14,14,0,0,0,4.39,2,18.64,18.64,0,0,0,4.76.72,13.84,13.84,0,0,0,6.55-1.59,13.07,13.07,0,0,0,2.88-1.94,20.91,20.91,0,0,0,2.38-2.59,20.19,20.19,0,0,0,3-5.19,33.5,33.5,0,0,0,2.3-13c0-7.06-.93-10.66-2.16-14-.79-2-2.88-7.27-8.57-9.86a14.2,14.2,0,0,0-4.46-1.37,20.85,20.85,0,0,0-3.1-.22A18.77,18.77,0,0,0,387.57,13.78Z" transform="translate(-0.79 -6.15)" />
    <path d="M463.17,10.18c.15-.79.22-1.58.36-2.37.07-.51.15-1.66,1-1.66.58,0,.65.36.65.86a39.4,39.4,0,0,1-.43,4.25c-.15,1.52-.22,3.1-.29,4.61s-.15,2.74-.15,4.18v4.46c.08.94,0,1.44-.72,1.44s-.72-.86-.86-2.16c-.07-1-.14-2-.29-3.09a23.78,23.78,0,0,0-4.53-3.53,21.4,21.4,0,0,0-11.45-3c-5.69,0-9.51,1.72-11.09,3.38a7.07,7.07,0,0,0-1.66,4.68,10,10,0,0,0,.72,3.53,7.7,7.7,0,0,0,2.67,2.59c.57.29,1.22.5,1.36.58,3.24,1.22,6.63,1.22,13.47,2.23l2.73.43c1.81.29,7.13,1.22,9.94,6.19a15.51,15.51,0,0,1,1.66,4.25,14.19,14.19,0,0,1,.36,3.31,18.11,18.11,0,0,1-.79,5.69,13.46,13.46,0,0,1-4,6.19,14.43,14.43,0,0,1-2.45,1.95A22.3,22.3,0,0,1,448,62.1a35.25,35.25,0,0,1-14.33-3c-.22,1.72-.43,3.52-.79,5.18-.07.58-.22,1.44-.87,1.44s-.72-.79-.72-1.22a8.68,8.68,0,0,1,.15-1.44c0-1.08.14-2.24.21-3.32.08-1.72.15-3.53.15-5.25a82.08,82.08,0,0,0-.51-9.44c0-.28-.07-.64-.07-1s.22-1.23.79-1.23.65.43.8,1.23c.28,1.72.5,3.45.79,5.25a17.82,17.82,0,0,0,5.9,4.75,22.67,22.67,0,0,0,12.17,3.24c8.57,0,12.1-2.66,12.1-7.56a7.08,7.08,0,0,0-.51-2.59c-.5-1.44-1.36-3.17-4.53-4.25a43.41,43.41,0,0,0-7.28-1.65c-2.16-.36-4.24-.65-6.4-.94-4.11-.65-6.12-1.15-7.85-2.23-.07-.07-1.23-.94-1.87-1.58a22,22,0,0,1-2.38-3.39,19.49,19.49,0,0,1-1.73-7.78A15.69,15.69,0,0,1,432.5,19c2.66-6.12,8.71-8.21,10.73-8.72a26.94,26.94,0,0,1,5.4-.5,36.71,36.71,0,0,1,14.11,2.38Z" transform="translate(-0.79 -6.15)" />
    <path d="M518.11,55.69c-.65,1.44-1.23,3-1.8,4.47-.29.93-.51,2.37-1.59,2.37a1.23,1.23,0,0,1-.86-.5c-.14-.36-8.21-21.1-8.35-21.39-1.59,4.18-3.31,8.28-5,12.46-.72,1.87-1.44,3.74-2.09,5.62a26,26,0,0,0-1,2.52c-.21.57-.36,1.29-1.08,1.29-1,0-1.15-1.22-1.73-2.66-.36-.87-.72-1.8-1.15-2.74l-4.1-10.37c-1.23-3-2.45-6-3.67-9.14-3.24-8.21-6.56-16.42-9.87-24.63-1.58-.21-3.09-.5-4.61-.72-1.15-.21-1.73-.29-1.73-1.08s.8-.72,1-.72c1.08,0,2,.07,2.95.15l3,.21c3.16.07,6.26.15,9.36.15,3.67,0,7.49-.08,11.23-.15,1.15,0,2.31,0,3.53-.07,1.66-.07,3.31-.22,5-.22.14,0,.79-.07.79.58s-.5.86-.93.94c-.94.21-1.73.36-2.67.5L509,28.83c.86-3.38,1.72-6.69,2.44-10,.44-2,.72-4,1-6a14,14,0,0,0-1.8-.36,6.09,6.09,0,0,1-1-.22c-.57-.07-1.29-.36-1.29-1.15,0-.36.29-.51,1.08-.51s1.29.08,2,.08l3,.21c2.81.15,5.54.22,8.28.22,3.74,0,7.49-.07,11.16-.15,1.87,0,3.74-.07,5.62-.21.79-.07,1.58-.15,2.44-.15.37,0,1.08.08,1.08.72a1.33,1.33,0,0,1-.64.72,44.68,44.68,0,0,1-5.33,1c-1.08,2.16-2.23,4.32-3.31,6.48-1.37,2.88-2.74,5.83-4,8.79s-2.73,6-4,9c-1.15,2.66-2.23,5.33-3.38,8-.8,2.09-1.66,4.1-2.52,6.12ZM484.84,13.06l14.48,38.17,5.18-13-9.94-25.27ZM510,31.35l7.78,20.31c.5-1.23,1.08-2.45,1.58-3.67.94-2.31,1.81-4.68,2.67-7,.57-1.65,1.22-3.24,1.73-4.9.72-2.08,1.44-4.17,2.09-6.26l2.8-9.07c.72-2.59,1.44-5.19,2.16-7.85h-12.6Z" transform="translate(-0.79 -6.15)" />
    <path d="M559.58,45c0,3.39-.07,6.55,0,9.87.07,1.51.14,3,.21,4.46,1.59-.07,3.17-.07,4.76-.14s3-.22,4.46-.36,3.31-.22,5-.44,3.38-.64,5.11-1c1.51-.36,3-.72,4.54-1.23a13,13,0,0,0,1.44-.57c.14-1.3.21-2.88.43-4.4.14-.57.36-1.58,1-1.58.86.07.79,1.44.79,1.51,0,.94-.14,1.8-.22,2.74S587,56.05,587,57.2c0,1.52,0,3,.07,4.25a23.66,23.66,0,0,1,.08,2.38c0,.14.07.86-.29,1.08,0,.07-.29.14-.43.14a.63.63,0,0,1-.65-.5,11.62,11.62,0,0,1-.51-2.59c-3-.36-6.12-.8-9.14-.94-2.52-.14-5-.14-7.56-.14h-8.14c-2.37,0-4.82,0-7.27.07-2.09.07-4.11.07-6.27.29a14.3,14.3,0,0,1-1.8.14s-1.08.07-1.08-.72a1.31,1.31,0,0,1,.15-.36c.29-.58.86-.5,1.94-.72a15.45,15.45,0,0,1,1.59-.29c1.22-.21,2.44-.36,3.74-.57.14-3.61.29-7.28.29-10.88V33.44c0-2.45.07-5.26,0-8,0-1.51-.07-3-.07-4.32,0-1.08,0-2-.08-3-.07-1.58-.21-3.24-.36-4.82-1.65-.22-3.52-.43-5.47-.79-.79-.22-2-.43-2-1.23,0-.36.28-.72.93-.72a6.36,6.36,0,0,0,1.23.15c1.44.07,2.88.21,4.24.29,1.59.14,3.17.07,4.69.14s2.8.14,4.17.14,2.45-.07,3.67,0c2.31,0,4.68.07,7,.07l6.26-.21c2-.14,3.82-.22,5.69-.5,1-.08,2-.29,3-.44.14-.57.21-1.15.36-1.8a3,3,0,0,1,.28-1.08c.15-.36.36-.57.65-.57s.72.21.72.93c0,.43-.07.79-.07,1.15a10.17,10.17,0,0,0-.14,1.44,29.46,29.46,0,0,0-.22,3.68c0,1.94.07,3.89.22,5.9,0,.36.07.72.07,1.23s-.15,1.22-.65,1.29a1.16,1.16,0,0,1-.5-.29c-.44-.5-.51-2.16-.72-3.88-.08-.65-.08-1.23-.22-1.88a22.4,22.4,0,0,0-2.45-1,49.2,49.2,0,0,0-10.94-2c-1.95-.21-4-.36-5.91-.5-1.73-.07-3.45-.07-5.18-.07-.22,3.52-.43,7-.43,10.58,0,3.89.07,7.85.07,11.74a96.88,96.88,0,0,0,17.93-1.52c1.65-.35,3.31-.79,5-1.22.14-1.08.36-2.16.57-3.31.07-.43.29-1.73,1-1.73s.65.58.65,1-.07.72-.07,1.08c-.08.65-.08,1.29-.15,1.94-.14,1.95-.21,3.82-.21,5.84,0,1.44,0,3,.07,4.39a19.57,19.57,0,0,1,.21,2c0,.44-.14,1.08-.64,1.08s-.94-1.15-1.16-2.37-.28-2.59-.43-3.75c-7-1.51-10.3-1.8-22.75-1.94Z" transform="translate(-0.79 -6.15)" />
    <path d="M610.7,22.14c-.15,6,0,12-.08,17.93v6.62c0,4.1.15,8.21.29,12.39,9.72-.44,13.18-.8,23-4,.15-1.15.22-2.37.36-3.53.07-.5.22-1,.29-1.51s.14-1.8,1.08-1.8c.58,0,.72.79.72,1.3a10.17,10.17,0,0,1-.14,1.44c0,.86,0,1.8-.08,2.66s-.07,1.66-.07,2.52c-.07,1.59.07,3.24.07,4.9v1.87a2.89,2.89,0,0,1,.08,1.15.86.86,0,0,1-.8.72c-.07-.07-.21-.07-.36-.14-.64-.43-.57-1.73-.57-1.8A1.26,1.26,0,0,0,634,62c-2-.29-4.11-.65-6.2-.8-3-.21-5.9-.21-8.92-.28-2.45,0-4.9-.15-7.28-.15-2.59,0-5.25.15-7.85.22-1.94.07-4,.14-6,.36a14.58,14.58,0,0,1-1.87.14c-.15,0-1,0-1-.79s1.29-.94,2.09-1.08c1.87-.36,3.67-.58,5.47-.86.14-2.89.29-5.77.29-8.65V43c0-5.62-.08-11.24,0-16.92,0-2.88.07-5.84,0-8.79-.08-1.29-.08-2.59-.15-4-2-.21-3.89-.5-5.83-.86-.58-.15-1.73-.22-1.73-1.16a.61.61,0,0,1,.22-.35,1.11,1.11,0,0,1,1-.29c.58,0,1.09.07,1.66.07s1,.07,1.44.14c.72.08,1.44.08,2.16.15,1.73.07,3.53.07,5.33.07s3.46,0,5.18-.07c.87,0,1.8-.07,2.74-.15.58-.07,1.08-.07,1.66-.14.43,0,.86-.07,1.37-.07s1.08.29,1.08.72c-.08.93-1.45,1-2.31,1.15a52,52,0,0,1-5.76.86,44.59,44.59,0,0,0-.14,4.47Z" transform="translate(-0.79 -6.15)" />
    <path d="M654.18,22.14c-.14,6,0,12-.07,17.93v6.62c0,4.1.15,8.21.29,12.39,9.72-.44,13.18-.8,23-4,.14-1.15.22-2.37.36-3.53.07-.5.22-1,.29-1.51s.14-1.8,1.08-1.8c.57,0,.72.79.72,1.3a8.91,8.91,0,0,1-.15,1.44c0,.86,0,1.8-.07,2.66s-.07,1.66-.07,2.52c-.07,1.59.07,3.24.07,4.9v1.87a2.9,2.9,0,0,1,.07,1.15.85.85,0,0,1-.79.72c-.07-.07-.21-.07-.36-.14-.64-.43-.57-1.73-.57-1.8a1.23,1.23,0,0,0-.44-.86c-2-.29-4.1-.65-6.19-.8-3-.21-5.9-.21-8.93-.28-2.44,0-4.89-.15-7.27-.15-2.59,0-5.26.15-7.85.22-1.94.07-4,.14-6,.36a14.74,14.74,0,0,1-1.88.14c-.14,0-1,0-1-.79s1.29-.94,2.08-1.08c1.88-.36,3.68-.58,5.48-.86.14-2.89.28-5.77.28-8.65V43c0-5.62-.07-11.24,0-16.92,0-2.88.08-5.84,0-8.79-.07-1.29-.07-2.59-.14-4-2-.21-3.89-.5-5.83-.86-.58-.15-1.73-.22-1.73-1.16a.61.61,0,0,1,.22-.35,1.11,1.11,0,0,1,1-.29c.58,0,1.08.07,1.66.07.43.07,1,.07,1.44.14.72.08,1.44.08,2.16.15,1.73.07,3.53.07,5.33.07s3.45,0,5.18-.07c.87,0,1.8-.07,2.74-.15.58-.07,1.08-.07,1.66-.14.43,0,.86-.07,1.36-.07.65,0,1.08.29,1.08.72-.07.93-1.44,1-2.3,1.15a52,52,0,0,1-5.76.86c-.07,1.52-.22,3-.15,4.47Z" transform="translate(-0.79 -6.15)" />
    <path d="M727.84,31.28c.58.43,1.66,1.37,1.66,2.23,0,.65-.72.72-.87.72-.36,0-1-.36-1.87-.57a12.26,12.26,0,0,0-3.17-.36A11.1,11.1,0,0,0,717,35.24,12.33,12.33,0,0,0,715.31,37a11.81,11.81,0,0,0-2.73,7.85c0,5.18,1.51,7.92,2.88,9.72,3.88,5,9.72,5.4,11.73,5.4A17.82,17.82,0,0,0,736.91,57a13.55,13.55,0,0,0,2.31-2.16,10.54,10.54,0,0,0,2.66-7A12.3,12.3,0,0,0,740,41.58,13.15,13.15,0,0,0,738.86,40c-1.44,1-2.88,1.95-4.25,3-.94.64-1.87,1.36-2.74,2.08a10.51,10.51,0,0,0-3,3.75c-.72,1.8-.43,3.24-.65,4a1,1,0,0,1-.86.93,1.1,1.1,0,0,1-.79-.5,15.62,15.62,0,0,1-.51-4.1A16.67,16.67,0,0,1,727.55,43a10,10,0,0,1,1-1.44,7.88,7.88,0,0,0,.72-1c1.87-2.16,6.12-4.75,6.19-4.83,1.8-1.22,3.53-2.37,5.4-3.45,1.23-.72,2.52-1.37,3.75-2.09a18.31,18.31,0,0,0,1.87-1.51c1.37-1.16,1.59-1.23,1.87-3a8.32,8.32,0,0,1-3,.87,2.56,2.56,0,0,1-1.15-.22A3.17,3.17,0,0,1,742,23.22a3.54,3.54,0,0,1,.36-1.44,3.69,3.69,0,0,1,3.24-2,3.8,3.8,0,0,1,2.38.72A5.62,5.62,0,0,1,750,24.94c0,4.11-2.45,7.92-5.33,10.66-1.22,1.15-2.66,2.09-4,3.17.43.65.93,1.3,1.29,1.94a11.13,11.13,0,0,1,1.88,6.34,14.28,14.28,0,0,1-.44,3.53,16,16,0,0,1-4.32,6.69c-2,1.81-6.91,4.69-14.11,4.69-3,0-10.58-.36-16.13-7a17.91,17.91,0,0,1-4.25-11.3,19.68,19.68,0,0,1,1-6.2,12.4,12.4,0,0,1,1.15-2.37,15,15,0,0,1,2.81-3.53,17.15,17.15,0,0,1,4-2.38,26.49,26.49,0,0,1,4.68-1.37c-.93-.93-4.17-3.67-4.17-8.2a7.49,7.49,0,0,1,.36-2.45,10.63,10.63,0,0,1,4.6-5.69c.58-.29,1.08-.58,1.59-.79a17.8,17.8,0,0,1,5-.79,10.14,10.14,0,0,1,7.2,2.73,11.28,11.28,0,0,1,2.52,3.31c.36.72.57,1,.57,1.44s-.28.8-.72.8c-.72-.08-1.65-1.23-3-2a9.18,9.18,0,0,0-10.3.29,10.74,10.74,0,0,0-1.36,1.15,7.69,7.69,0,0,0-1.66,4.75A11.56,11.56,0,0,0,720,26.53a6.37,6.37,0,0,0,.94,1.44A12.73,12.73,0,0,1,727.84,31.28Z" transform="translate(-0.79 -6.15)" />
    <path d="M813.67,20.48A30.94,30.94,0,0,0,809.06,15a13.94,13.94,0,0,0-1.95-1.51c-1.58-1-3.6-2-7.7-2a16.08,16.08,0,0,0-10.08,3.17,20.22,20.22,0,0,0-5,6.62,36.16,36.16,0,0,0-2.74,14.4c0,7,1.52,11,2.88,13.76a21.43,21.43,0,0,0,2.31,3.6,17,17,0,0,0,5.76,4.61,20.84,20.84,0,0,0,7.7,1.51,21.39,21.39,0,0,0,10.37-2.81,28.88,28.88,0,0,0,3.82-2.81,21,21,0,0,0,2.16-2.38c.36-.36.65-.86,1.22-.86a.71.71,0,0,1,.58.79.88.88,0,0,1-.15.58,18.42,18.42,0,0,1-1.87,2.45,23.38,23.38,0,0,1-4.68,4,24.7,24.7,0,0,1-14.4,4.25A27.62,27.62,0,0,1,784,59.08a17.82,17.82,0,0,1-2.74-1.88c-.57-.43-1.08-.86-1.65-1.37a38,38,0,0,1-3-3.81,32,32,0,0,1-3-6.63,31.18,31.18,0,0,1-1-8.2,27.05,27.05,0,0,1,5.62-17.14,27,27,0,0,1,5.76-5.33c.86-.58,1.8-1.15,2.73-1.66a27.18,27.18,0,0,1,13.11-3.31A41.73,41.73,0,0,1,811,11.62c1.59.51,3.17,1.16,4.68,1.8.36-1.44.65-2.95.94-4.46.21-1.08.14-1.59.72-2.09,0,0,.29-.22.5-.14s.58.28.58.93a19.14,19.14,0,0,1-.22,2.09c-.14,1-.29,2.09-.36,3.1-.29,2.37-.43,4.68-.57,7,0,1.52-.08,3-.08,4.47s0,2.95.08,4.39c0,.94-.22,1.58-.8,1.58a.65.65,0,0,1-.57-.36c-.36-.64-.72-6.33-.79-7C814.67,22.14,814.17,21.27,813.67,20.48Z" transform="translate(-0.79 -6.15)" />
    <path d="M825.11,34.45a20,20,0,0,1,.29-2.16,25.94,25.94,0,0,1,4.68-11.59,22.45,22.45,0,0,1,2-2.45,21.31,21.31,0,0,1,4.32-3.67,31.27,31.27,0,0,1,9.87-4.18,32.13,32.13,0,0,1,5.83-.65,36.46,36.46,0,0,1,10.59,1.44,25.5,25.5,0,0,1,3.81,1.73,21.87,21.87,0,0,1,4.75,3.24,27.12,27.12,0,0,1,3.75,4.1c4.25,6.12,4.32,13.61,4.32,15.13A28.81,28.81,0,0,1,876,49.07a27,27,0,0,1-6.27,7.41,27.69,27.69,0,0,1-2.66,1.8,26.88,26.88,0,0,1-14.84,4c-2.3,0-11.88,0-19.51-7.13a22,22,0,0,1-5.62-8.07,27.35,27.35,0,0,1-1.94-8.64c-.07-.86,0-1.73,0-2.66Zm18.5-20.67a15.69,15.69,0,0,0-2.37,2,14.26,14.26,0,0,0-1,1.08,30.1,30.1,0,0,0-2.74,4.18,34,34,0,0,0-3,13.46c0,1.15-.07,2.31,0,3.46s.15,2,.22,3a28.28,28.28,0,0,0,2.59,9.29,19.91,19.91,0,0,0,2.45,4.18,17,17,0,0,0,1.51,1.8,13.16,13.16,0,0,0,1.08,1c.43.29.86.65,1.22.94a14,14,0,0,0,4.4,2,18.47,18.47,0,0,0,4.75.72,13.82,13.82,0,0,0,6.55-1.59,12.61,12.61,0,0,0,2.88-1.94,20.91,20.91,0,0,0,2.38-2.59,20.19,20.19,0,0,0,2.95-5.19,33.5,33.5,0,0,0,2.3-13c0-7.06-.93-10.66-2.16-14-.79-2-2.88-7.27-8.56-9.86a14.19,14.19,0,0,0-4.47-1.37,20.72,20.72,0,0,0-3.09-.22A18.8,18.8,0,0,0,843.61,13.78Z" transform="translate(-0.79 -6.15)" />
    <path d="M892.79,57.2a4.78,4.78,0,0,1-1.51,3.46,4.39,4.39,0,0,1-3.17,1.15,3.91,3.91,0,0,1-2.81-1.08,4.26,4.26,0,0,1-1.73-3.6,4.15,4.15,0,0,1,.15-1.15,4.62,4.62,0,0,1,9.07,1.22Z" transform="translate(-0.79 -6.15)" />
  </StyledSVG>
);

export default Logo;
