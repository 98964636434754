import PropTypes from 'prop-types';
import React from 'react';

const TransitionContext = React.createContext('');

const useTransition = () => {
  const transition = React.useContext(TransitionContext);

  return transition;
};

// eslint-disable-next-line react/display-name
const withTransition = (Component) => (props) => {
  const transition = useTransition();

  return <Component {...props} transition={transition} />;
};

const TransitionProvider = ({
  children = null,
  transition = '',
}) => (
  <TransitionContext.Provider value={transition}>
    {children}
  </TransitionContext.Provider>
);

TransitionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  transition: PropTypes.string,
};

export { TransitionProvider, useTransition, withTransition };
