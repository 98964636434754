const slide = {
  initial: {
    y: '100vh',
  },
  animate: {
    transition: {
      duration: 0.5,
    },
    y: 0,
  },
  exit: {
    transition: {
      duration: 0.5,
    },
    y: '100vh',
  }
};

const fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  }
};

const transitions = {
  fade,
  slide,
};

export default transitions;
