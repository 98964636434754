import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useNav } from '../hooks/NavProvider';
import i18n from '../i18n';
import { getAuctionNumber, getLotNumber } from '../methods/catalog';
import { getTranslation } from '../methods/common';

const HelmetConfig = ({
  auction = {},
  lot = {},
  news = {},
}) => {
  const { t } = useTranslation();
  const { getMatches } = useNav();
  const match = getMatches(true).slice(-1);
  const langMatch = match.length > 0 ? match[0].data.find((m) => m.lang === i18n.getLang()) : {};
  const title = langMatch.title || '';
  const metaDescription = langMatch.metaDescription || '';

  const renderTitle = () => {
    const titleParts = [];
    if (news.data) {
      titleParts.push(`${getTranslation(news.data, 'title')} ~`);
    }
    if (lot?.number) {
      titleParts.push(t('lots.number'));
      titleParts.push(`${getLotNumber('', lot)}:`);
    }
    if (lot?.title) titleParts.push(`${getTranslation(lot, 'title')} ~`);
    if (title) titleParts.push(title);
    if (auction?.number) titleParts.push(getAuctionNumber(auction));
    if (titleParts.length) titleParts.push('~');
    titleParts.push('Rippon Boswell & Co.');
    return titleParts.join(' ');
  };

  return (
    <Helmet>
      <title>{renderTitle()}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
};

HelmetConfig.propTypes = {
  auction: PropTypes.shape({
    number: PropTypes.string,
  }),
  lot: PropTypes.shape({
    number: PropTypes.number,
    title: PropTypes.string,
  }),
  news: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default HelmetConfig;
