// https://www.npmjs.com/package/@jonkoops/matomo-tracker-react
import { createInstance } from '@jonkoops/matomo-tracker-react';

const disabled = process.env.REACT_APP_MATOMO_DISABLED;
const urlBase = process.env.REACT_APP_MATOMO_URL;
const siteId = process.env.REACT_APP_MATOMO_SITE_ID;

const matomo = () => createInstance({
  urlBase,
  siteId,
  disabled, // optional, false by default. Makes all tracking calls no-ops if set to true.
  // userId: 'UID76903202', // optional, default value: `undefined`.
  // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  // heartBeat: { // optional, enabled by default
  //   active: true, // optional, default value: true
  //   seconds: 10 // optional, default value: `15
  // },
  // linkTracking: false, // optional, default value: true
  // configurations: { // optional, default value: {}
  //   // any valid matomo configuration, all below are optional
  //   disableCookies: true,
  //   setSecureCookie: true,
  //   setRequestMethod: 'POST'
  // }
});

export default matomo;
