import { Box, ButtonBase, styled, Tooltip } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Box)({
  position: 'absolute',
  top: '-2.75rem',
  left: '0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CircleButton = styled(ButtonBase)(({ theme }) => ({
  padding: 0,
  width: '3rem',
  minWidth: '3rem',
  height: '3rem',
  borderRadius: '1.5rem',
  backgroundColor: theme.palette.background.lighterbrown,
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const StyledIcon = styled(ArrowDropUpIcon)(({ theme }) => ({
  fontSize: '3rem',
  color: 'white',
  '&:hover': {
    color: theme.palette.background.lighterbrown,
  },
}));

const ScrollToTop = () => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const toggleShow = () => setShow(window.scrollY > 200);
    window.addEventListener('scroll', toggleShow);
    return () => window.removeEventListener('scroll', toggleShow);
  }, []);

  return show ? (
    <Box sx={{ position: 'relative' }}>
      <StyledContainer>
        <Tooltip title={t('general.scrollTop')}>
          <CircleButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <StyledIcon />
          </CircleButton>
        </Tooltip>
      </StyledContainer>
    </Box>
  ) : null;
};

export default ScrollToTop;
